.terms-privacy-content {
    padding: 20px;
    margin: auto;
    max-width: 800px; /* Adjust width as needed */
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow to the container */
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 300;
  }
  
  .terms-privacy-content h1 {
    text-align: center;
    margin-bottom: 25px;
  }
  
  .terms-privacy-content p {
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
    line-height: 1.5em;
  }

  .terms-privacy-content strong{
    font-weight: bold !important;
  }

  .terms-privacy-content h2 {
    text-align: justify;
    font-size: 1.5em;
    font-weight: bold;
  }
  