/* CSS in a separate stylesheet or in your component's CSS file */

.contact-us {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.newContestFormGroup {
  margin-bottom: 20px;
}

.newContestFormGroup input,
.newContestFormGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.newContestFormGroup textarea {
  resize: vertical;
  /* Allow vertical resizing of textarea */
}

.submitContest {
  background-color: #020202;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitContest :hover {
  background-color: #0056b3;
  /* Darker shade on hover */
}

/* Add more specific styling as needed */