.login-page {
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-family: "Source Sans Pro";
    line-height: 70px;
    margin-bottom: 0px;
}

label {
    color: #030303;
    font-size: 14px;
    font-family: "Source Sans Pro";
    line-height: 50px;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group input {
    top: 318px;
    left: 333px;
    width: 60%;
    height: 63px;
    padding: 0px 8px;
    border: 1px solid #282828;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: #ffffff;
    color: #282828;
    font-size: 14px;
    font-family: "Source Sans Pro";
    line-height: 19px;
    outline: none;
    margin-bottom: 20px;
}

.loginButton {
    top: 551px;
    left: 333px;
    padding: 10px 10px;
    border: 0px solid #282828;
    box-sizing: border-box;
    border-radius: 24px;
    background-color: #d9d6e3;
    color: #000000;
    font-size: 14px;
    font-family: "Source Sans Pro";
    line-height: 16px;
    outline: none;
}