.countdown .content {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: auto;
	gap: 0.5%;
}
.countdown h2 {
	margin-bottom: 20px;
	font-size: 50px;
}

.countdown .box {
	width: calc(100% / 4);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 150px;
	max-width: 110px;
}

.countdown .value {
	font-size: 30px;
	font-weight: 600;
	/* background-color: #144b40; */
    background-color: #969696;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80px;
	height: 80px;
	position: relative;
	box-shadow: -2px 0px 5px 1px #232423ad;
}

/* @media screen and (min-width: 550px) {
	.countdown .content {
		gap: 30px;
	}
	.countdown .value {
		font-size: 50px;
		width: 120px;
		height: 120px;
	}
	.countdown .box {
		height: 200px;
		max-width: 200px;
	}
}

@media screen and (min-width: 790px) {
	.countdown .value {
		font-size: 50px;
		height: 50%;
		width: 150px;
	}
	.countdown .box {
		max-width: 200px;
		height: 250px;
	}
} */

.countdown .value::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	z-index: 2;
	background-color: #313131b0;
	box-shadow: 0px 0px 5px 0px #2e2f2ed6;
}
.countdown .value::after {
	content: "";
	position: absolute;
	z-index: 2;
	left: -7px;
	width: 10px;
	height: 20px;
	background-color: #969696;
	box-shadow: inset -1px 1px 5px 1px #0e0e0ead;
}

.countdown .label {
	font-size: 20px;
	font-weight: 100;
	letter-spacing: 4px;
	margin-top: 10px;
}