@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.create-contest {
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

.create-contest h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.createContestFormGroup {
  margin-bottom: 20px;
}

.createContestFormGroup label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.createContestFormGroup textarea {
  width: 100%;
  height: auto;
  min-height: 120px;
  /* Adjust as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

#title, #contact, #author, #award {
  width: 100%;
  min-height: 40px;
  height: 40px !important;
  /* Adjust as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.createContestFormGroup input[type="file"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}