body, html {
  background-color: #ffffff !important;
  margin: 0;
  padding: 0;
}

.signup-page {
  /* Additional styling for your signup page if needed */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center the content */
  margin-top: 10%;
  height: auto;
  color: #ffffff;
  width: 100%;
}

.signup-page input {
  top: 318px;
  left: 333px;
  height: 63px;
  padding: 0px 8px;
  border: 1px solid #282828;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #282828;
  font-size: 14px;
  font-family: "Source Sans Pro";
  line-height: 19px;
  outline: none;
  margin-bottom: 50px;
}

.name-input {
  height: 63px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #282828;
  font-size: 14px;
  font-family: "Source Sans Pro";
  outline: none;
}

.firstname-input {
  height: 63px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #282828;
  font-size: 14px;
  font-family: "Source Sans Pro";
  outline: none;
  flex: 1;
}

.lastname-input {
  height: 63px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  color: #282828;
  font-size: 14px;
  font-family: "Source Sans Pro";
  outline: none;
  flex: 1;
}

.name-group {
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
}

.email-password-input {
  width: 100%;
}

.signUpButton {
  background-color: #d9d6e3;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  transition: background-color 0.3s;
  font-size: 14px;
  font-family: "Source Sans Pro";
  margin-bottom: 5%;
}

button[type="submit"]:hover {
  background-color: #990000;
  /* Darker shade on hover */
}