/* ContestDetails.css */
.contest-details-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.contest-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.contest-image {
  max-width: 100%;
  width: 50%;
  height: auto;
  border-radius: 8px;
  /* Optional, if you want rounded corners */
}

.contest-info-container {
  display: flex;
}

.contest-title {
  margin-bottom: 0.5em;
}

.contest-info {
  width: 60%;
  margin-inline: 10%;
}

.contest-description {
  margin-bottom: 1em;
  white-space: pre-wrap;
  /* Preserves whitespace and wraps text */
  text-align: left;
}

.contest-meta {
  display: flex;
  flex-direction: column;
}

.contest-meta>div {
  margin-bottom: 0.5em;
}

.tag {
  display: inline-block;
  background-color: #efefef;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

.createSubmission {
  margin-top: 3%;
  background-color: #007bff;
  width: 80%;
  align-self: center;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Adjust the styles as needed to match your design */