/* src/components/NavBar.css */
.custom-navbar {
    background-color: #007bff;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 50px;
  }

  .navbar-image {
    max-height: 100%;
    height: 80px;
    width: 80px;
    transform: rotate(-8deg);
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    display: inline;
    .text {
        color: #030303;
        font-size: 32px;
        font-family: "Source Sans Pro";
        font-weight: 500;
        line-height: 38px;
    }      
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.2s;
  }
  
  .nav-link:hover {
    color: #f8f8f8;
  }
  