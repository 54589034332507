@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.footer {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
    position: relative;
    top: 0%;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Ensure it's responsive */
    /* max-width: 1200px; Adjust max-width as needed */
    margin: 0 10px;
    height: 15%;
  }
  
  .footer-section {
    /* flex: 1; */
    min-width: 140px; /* Minimum width to prevent squishing on smaller screens */
  }

  .footer-section p{
    margin-top: 15px;
  }
  
  .footer-section a{
    /* display: block; */
    color: #000; /* Your desired link color */
    text-decoration: none; /* Optional: Removes underline from links */
    font-weight: bold; /* Make the link text bold */
    margin: 0 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .footer-content {
      flex-direction: column;
    }
    .footer-section {
      margin-bottom: 10px; /* Add some margin when stacked */
    }
}