@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.home {
  /* font-family: 'Roboto', sans-serif; */
  color: #333;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
}

.home-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  margin-bottom: 1%;
}

.text-content {
  flex: 1;
  padding-right: 20px;
  /* Adjust as necessary */
}

.image-container {
  flex: 1;
  text-align: right;
  /* Adjust as necessary */
}

.header-illustration {
  max-width: 100%;
  height: auto;
}

.header-title {
  font-size: 2.2em;
  font-weight: 500;
  margin-bottom: 30px;
}

.header-subtitle {
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 1.5em;
  font-family: Arial, Helvetica, sans-serif;
}

.registration-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.google-btn,
.email-btn {
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  font-weight: 500;
}

.user-types {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  text-align: left;
}

.user-types-title {
  text-align: left;
  margin-left: 30px;
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.user-type {
  flex: 1;
  margin: 0 10px;
}

.user-type-photo {
  width: 140px;
  height: 130px;
  margin-bottom: 10px;
}

.user-type h3 {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
}

.user-type p {
  font-family: Arial, Helvetica, sans-serif;
}

/* MainApp.css */
.main-app {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5%;
  padding: 20px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title h2 {
  font-size: 24px;
  color: #333;
}

.section-title a {
  text-decoration: none;
  color: #1a73e8;
  font-size: 16px;
}

.card-container {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  overflow-x: auto;
}

.card {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
  text-align: left;
}

.card h3 {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
}

.card p {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.backgroundVideo {
  position: relative;
  /* top: 0; */
  /* right: 0; */
  min-width: 100%;
  /* min-height: 100%; */
  width: auto;
  height: 850px;
  object-fit: cover;
  /* z-index: -1; */
}

.overlay {
  z-index: 1;
  margin-top: 5%;
  padding: 50px 20px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.home-image {
  /* max-height: 100%; */
  height: 80%;
  width: 60%;
  transform: rotate(-8deg);
  margin-bottom: 5%;
}

.enter-contest-button {
  background-color: #ffd700;
  color: #000;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10%;
  border-radius: 15px;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 10%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 20px;
  }

  .user-types {
    display: block;
    justify-content: space-between;
    padding: 20px;
    text-align: left;
  }

  .header-content {
    display: block;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 5%;
  }

  /*Inter,sans-serif*/
}