.profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.profile-info {
  margin-bottom: 20px;
}

.profile-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.profile-field label {
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}

.profile-field input[type="text"],
.profile-field input[type="email"] {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.profile-field span {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
}

.profile-actions {
  text-align: center;
}

.profile-actions button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px;
}

.profile-actions button:hover {
  background-color: #0056b3;
}

.submitted-contests {
  margin-top: 20px;
}

.submitted-contests h3 {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.submitted-contests ul {
  list-style: none;
  padding: 0;
}

.submitted-contests li {
  margin-bottom: 5px;
  color: #333;
}